/* globals Swiper Swiper */

import $ from 'jquery'

function setUpSlider ($slider) {
  new Swiper($slider[0], {
    loop: true,
    slidesPerView: 'auto',
    loopedSlides: 5,
    preventClicks: true,
    preventClicksPropagation: false,
    runCallbacksOnInit: true,
    autoplayDisableOnInteraction: false,
    centeredSlides: false,
    pagination: {
      el: '.swiper-pagination'
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  })
}

/* ========================================
 * Document Ready
 * ========================================= */
$(() => {
  const $mainSlider = $('.swiper-container')
  setUpSlider($mainSlider)
})
